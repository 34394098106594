import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app';
import HttpClient from './network/http';
import Installation from './service/installation';
import axios from 'axios';
import "./utils/i18n"

const baseURL = window.__ENV__?.REACT_APP_BASE_URL || "https://admin-server.koolcharge.com";
console.log("✅ Loaded REACT_APP_BASE_URL:", baseURL);
const httpClient = new HttpClient(baseURL);
const installation = new Installation(httpClient);
axios.defaults.baseURL = baseURL;

ReactDOM.render(
    <BrowserRouter>
        <App installation={installation} />
    </BrowserRouter>,
  document.getElementById('root')
);

