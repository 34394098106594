import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../css/dealership.module.css";
import mainStyles from "../../css/style.module.css";

function DealershipPage(props) {

	const Header = props.makeHeader();
	const { t } = useTranslation();

	useEffect(() => {
		document.title = t("쿨차지 대리점모집");
	},[t])

	return (
		<div className={styles.dealership}>
			<section className={mainStyles.main_visual}>
				{Header}
				<div className={[mainStyles.main_bg, styles.bg].join(" ")}>
					<div className={styles.reference}>
						Designed by <a href={"https://www.freepik.com"}>freepik.com</a>
					</div>
				</div>
				<h2 className={[mainStyles.h2, styles.mainText].join(" ")}>
					<div>{t("대리점모집")}</div>
					<div className={styles.description}>
						충전기부터 CSMS까지 충전 사업에 필요한 모든 것을 제공하는 쿨차지와<br/>
						<b>함께하실 영업대리점과 협력사를 모집합니다.</b><br/>
						<br/>
						<b className={styles.contact}>
							상담문의<br/>
							1660-0481(010-5555-0322)<br/>
							woo@koolsign.net
						</b>
					</div>
				</h2>
				<div className={mainStyles.scroll_downs}>
					<div className={mainStyles.mousey}>
						<div className={mainStyles.scroller}></div>
					</div>
				</div>
			</section>
			<section className={styles.content}>
				<ul className={styles.list}>
					<li>∙ ’24년 환경부 보조사업자 지정</li>
					<li>∙ 국내 유일 IP65 등급 충전기</li>
					<li>∙ 스마트충전기(PLC 모뎀 탑재 화재 예방형 충전기)</li>
					<li>∙ ISO 15118-2 VAS 구현 및 실차 시험 완료</li>
					<li>∙ 완속, 중속, 급속, 초급속 풀 라인업 확보</li>
				</ul>
				<div className={styles.finding}>
					EVC 시장의 선두 주자로서, 쿨차지와 함께<br/>
					<b>혁신과 성장을 이루고자 하는 열정 있는 파트너를 찾습니다.</b>
				</div>
			</section>
		</div>
	);

}

export default DealershipPage;
